import React from 'react';
import SEO from '../components/seo';

const NotFound = () => (
  <>
    <SEO title="404: Not found" />
    <h1>404</h1>
  </>
);

export default NotFound;
